import { FunctionComponent } from "react";
import styles from "./frame-who-we-are.module.css";

const FrameWhoWeAre: FunctionComponent = () => {
  return (
    <div className={styles.frameWhoWeAre}>
      <h1 className={styles.whatWeAre}>what we are</h1>
      <div className={styles.frameParent}>
        <div className={styles.lookingForParent}>
          <h1 className={styles.lookingFor}>looking for</h1>
          <div className={styles.vectorWrapper}>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/rectangle-5.svg"
            />
          </div>
        </div>
        <div className={styles.depth7Frame0Parent}>
          <img
            className={styles.depth7Frame0}
            loading="lazy"
            alt=""
            src="/depth-7-frame-0@2x.png"
          />
          <div className={styles.frameWrapper}>
            <div className={styles.atMonkeyflapWereOnAnExcParent}>
              <div className={styles.atMonkeyflapWereContainer}>
                <p className={styles.atMonkeyflapWere}>
                  At MonkeyFlap, we're on an exciting journey as an emerging
                  company in the gaming industry. Currently in early access,
                  we're dedicated to bringing innovative gaming experiences to
                  players worldwide. With a talented team behind us, we're
                  actively seeking financing opportunities to further develop
                  our two groundbreaking games.
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.weWelcomePotential}>
                  We welcome potential investors and partners to join us on this
                  adventure. If you're interested in exploring investment
                  opportunities or learning more about our projects, we're eager
                  to connect and share our elevator pitch. Together, let's shape
                  the future of gaming and unlock the full potential of
                  MonkeyFlap.
                </p>
              </div>
              <button className={styles.rectangleParent}>
                <div className={styles.frameItem} />
                <div className={styles.contactUs}>Contact Us</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameWhoWeAre;
