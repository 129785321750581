import { FunctionComponent } from "react";
import WhatWeAreLookingFor from "../components/what-we-are-looking-for";
import FrameWhoWeAre from "../components/frame-who-we-are";
import styles from "./monkeyflap.module.css";

const Monkeyflap: FunctionComponent = () => {
  return (
    <div className={styles.monkeyflap}>
      <div className={styles.monkeyflapLogo}>
        <header className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <img
            className={styles.capa2Icon}
            loading="lazy"
            alt=""
            src="/capa-2.svg"
          />
          <div className={styles.frameWrapper}>
            <img
              className={styles.frameItem}
              loading="lazy"
              alt=""
              src="/group-1.svg"
            />
          </div>
          <div className={styles.cofounders}>
            <div className={styles.generalParent}>
              <div className={styles.general}>General</div>
              <div className={styles.whoWeAre}>Who we are</div>
              <div className={styles.careers}>Careers</div>
            </div>
          </div>
          <button className={styles.button}>
            <div className={styles.buttonChild} />
            <div className={styles.whitepaper}>Whitepaper</div>
          </button>
        </header>
        <div className={styles.header}>
          <img className={styles.headerChild} alt="" src="/group-14@2x.png" />
          <h1 className={styles.ourMission}>OUR MISSION</h1>
          <div className={styles.atMonkeyflapWereNotJustWrapper}>
            <h3 className={styles.atMonkeyflapWereContainer}>
              <p
                className={styles.atMonkeyflapWere}
              >{`At Monkeyflap, we're not just another gaming startup; we're a passionate team with big dreams and even bigger ambitions. We are driven by an unwavering pursuit of excellence in game development. With our flagship titles 'Infernal's Sacrifice' and 'Eldertales', we're forging our own path in the gaming industry, pushing boundaries and challenging conventions. `}</p>
              <p className={styles.blankLine}>&nbsp;</p>
              <p className={styles.eachMemberOf}>
                Each member of our team brings unique talents and perspectives,
                allowing us to craft immersive worlds that captivate players and
                leave a lasting impact. Join us on our journey as we defy
                expectations, break barriers, and pave the way for the next
                generation of gaming experiences
              </p>
            </h3>
          </div>
        </div>
      </div>
      <main className={styles.whatWeAreLookingForParent}>
        <WhatWeAreLookingFor />
        <section className={styles.frameWhoWeAreWrapper}>
          <FrameWhoWeAre />
        </section>
        <section className={styles.frameWhoWeAreParent}>
          <div className={styles.frameWhoWeAre}>
            <h1 className={styles.partners}>PARTNERS</h1>
            <div className={styles.trustedLogos}>
              <div className={styles.contactCTA}>
                <h1 className={styles.whoTrustIn}>WHO TRUST IN US</h1>
                <div className={styles.contactCTAInner}>
                  <img
                    className={styles.frameInner}
                    loading="lazy"
                    alt=""
                    src="/rectangle-5-1.svg"
                  />
                </div>
              </div>
              <div className={styles.monkeyflapCopyright}>
                <div className={styles.logosColumn}>
                  <div className={styles.logosColumnChild} />
                  <img
                    className={styles.image1Icon}
                    loading="lazy"
                    alt=""
                    src="/image-1@2x.png"
                  />
                </div>
                <div className={styles.logosColumn1}>
                  <div className={styles.logosColumnItem} />
                  <img
                    className={styles.logosColumnInner}
                    loading="lazy"
                    alt=""
                    src="/group-13.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className={styles.footer}>
            <div className={styles.rectangleGroup}>
              <div className={styles.rectangleDiv} />
              <div className={styles.monkeyflapAllRights}>
                {" "}
                MonkeyFlap. All rights reserved.
              </div>
              <div className={styles.basedInBarcelonaSpainWrapper}>
                <div className={styles.basedInBarcelona}>
                  Based in Barcelona - Spain
                </div>
              </div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default Monkeyflap;
