import { FunctionComponent } from "react";
import styles from "./what-we-are-looking-for.module.css";

const WhatWeAreLookingFor: FunctionComponent = () => {
  return (
    <section className={styles.whatWeAreLookingFor}>
      <h1 className={styles.whoWeAre}>who we are</h1>
      <div className={styles.frameParent}>
        <div className={styles.whatWeDoParent}>
          <h1 className={styles.whatWeDo}>what we do</h1>
          <div className={styles.rectangleWrapper}>
            <div className={styles.frameChild} />
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.depth7Frame2Parent}>
              <img
                className={styles.depth7Frame2}
                loading="lazy"
                alt=""
                src="/depth-7-frame-2@2x.png"
              />
              <div className={styles.alexisPifanoParent}>
                <h1 className={styles.alexisPifano}>alexis pifano</h1>
                <h3 className={styles.coFounder}>co-founder</h3>
              </div>
            </div>
            <div className={styles.depth7Frame1Parent}>
              <img
                className={styles.depth7Frame1}
                loading="lazy"
                alt=""
                src="/depth-7-frame-1@2x.png"
              />
              <div className={styles.whitepaperButton}>
                <div className={styles.capa}>
                  <h1 className={styles.victorMoya}>Victor moya</h1>
                  <div className={styles.generalWhoWeAre}>
                    <h3 className={styles.coFounder1}>co-founder</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.leadTheEstablishmentContainer}>
            <p className={styles.leadTheEstablishment}>
              Lead the establishment and strategic direction of MonkeyFlap, an
              emerging company in the gaming industry.
            </p>
            <p className={styles.developAndExecute}>
              Develop and execute the corporate vision, defining short and
              long-term goals for the sustainable growth of the company.
            </p>
            <p className={styles.overseeAllBusiness}>
              Oversee all business operations, including human resources
              management, finances, product development, and marketing.
            </p>
            <p className={styles.guideTheTeam}>
              Guide the team in the successful creation and launch of our
              flagship games, "Infernal's Sacrifice" and "Eldertales", from
              conceptualization to market release. Cultivate strategic
              relationships with investors, business partners, and industry
              media to drive brand expansion and recognition. Foster a company
              culture centered on innovation, collaboration, and personal
              growth, inspiring team members to reach their full potential.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeAreLookingFor;
